import { HomePage } from "design-system/sites/home/home-page/home-page";
import { createSiteHeaderProps } from "../universal/site-header.props";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { createSiteFooterProps } from "../universal/site-footer.props";
import { createMissionTopperProps } from "../toppers/mission-topper/mission-topper.props";
import { LandingPageEntry } from "frontend/contentful/schema/pages";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { MissionTopperEntry } from "frontend/contentful/schema/toppers";
import { MissionTopperProps } from "design-system/sites/home/mission-topper/mission-topper";
import { ContentfulHead } from "../primitives/head.contentful";
import { ContentfulPageSections } from "./page-sections.contentful";
import { useContentful } from "frontend/hooks/use-contentful";

export const ContentfulHomePage = createContentfulComponent<LandingPageEntry>(
  ({ entry }) => {
    const { getEntry, site } = useContentful();

    let topperProps: MissionTopperProps | undefined = undefined;

    const topper = getEntry(entry.fields.topper);

    if (isContentType<MissionTopperEntry>("missionTopper", topper)) {
      topperProps = createMissionTopperProps(topper);
    }

    return (
      <>
        <ContentfulHead entry={entry.fields.seo} />

        <HomePage
          topper={topperProps}
          header={{ name: "SiteHeader", props: createSiteHeaderProps(site) }}
          footer={createSiteFooterProps(site?.fields.footer)}
        >
          <ContentfulPageSections entry={entry} />
        </HomePage>
      </>
    );
  },
);
