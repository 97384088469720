import "./mission-topper.scss";

import { useState, useRef, useMemo, ReactNode } from "react";
import cn from "clsx";
import { useEventListener } from "design-system/hooks/use-event-listener";
import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { EditAttributes } from "design-system/types/types";
import { Icon } from "design-system/components/icons/icon";

export interface MissionTopperTextPartCta {
  text: ReactNode;
  linkText?: string;
  linkUrl?: string;

  editAttributes?: {
    url?: EditAttributes;
    text?: EditAttributes;
    linkText?: EditAttributes;
  };
}

export interface MissionTopperTextPartDetail {
  cta: MissionTopperTextPartCta;
  media: MediaAssetProps;
}

export interface MissionTopperTextPart {
  text: string;
  details?: MissionTopperTextPartDetail;
}

export interface MissionTopperProps {
  parts: Array<MissionTopperTextPart>;
  defaultMedia: MediaAssetProps;

  editAttributes?: {
    text?: EditAttributes;
  };
}

const DISABLE_HOVER_CLASS = "hbs-disable-hover";
let scrollTimeout: ReturnType<typeof setTimeout>;

/**
 * ## See it in use on...
 * - The [Homepage](/story/example-pages-homepage--homepage)
 */
export function MissionTopper({
  parts,
  defaultMedia,
  editAttributes,
}: MissionTopperProps) {
  if (!Array.isArray(parts)) parts = [];

  const [activePartIndex, setActivePartIndex] = useState<number | null>(null);
  const wrapperEl = useRef<HTMLDivElement>(null);
  const mediaEl = useRef<HTMLDivElement>(null);

  if (defaultMedia.image) {
    defaultMedia.image.loading = "eager";
  }

  /**
   * Update the active index and page offset whenever the user scrolls manually
   */
  useEventListener(
    "scroll",
    () => {
      if (scrollTimeout) clearTimeout(scrollTimeout);

      const element = wrapperEl.current;
      if (!element) return;

      if (!element.classList.contains(DISABLE_HOVER_CLASS)) {
        element.classList.add(DISABLE_HOVER_CLASS);
      }

      scrollTimeout = setTimeout(() => {
        element.classList.remove(DISABLE_HOVER_CLASS);
      }, 500);
    },
    typeof window !== "undefined" ? window : undefined,
  );

  const slides = useMemo(
    () =>
      parts
        .map((part, i) => ({ partIndex: i, ...part }))
        .filter((part) => !!part.details),
    [parts],
  );

  const firstSlide = useMemo(() => slides[0], [slides]);
  const lastSlide = useMemo(() => slides[slides.length - 1], [slides]);

  const currentSlide = useMemo(
    () => slides.find((slide) => slide.partIndex === activePartIndex),
    [slides, activePartIndex],
  );

  const currentSlideIndex = useMemo(() => {
    if (!currentSlide) return -1;
    return slides.findIndex(
      (slide) => slide.partIndex === currentSlide.partIndex,
    );
  }, [slides, currentSlide]);

  const changeActivePart = (index: number | null) => {
    const animationDelay = 250;
    const animateOutClass = "hbs-mission-topper__media-inner--fadeout";
    const container = mediaEl.current;
    container?.classList.add(animateOutClass);
    // Wait for animation to finish before updating the active part
    setTimeout(() => {
      setActivePartIndex(index);
      container?.classList.remove(animateOutClass);
    }, animationDelay);
  };

  return (
    <div
      className="hbs-mission-topper hbs-topper-wrapper"
      ref={wrapperEl}
      data-theme="white"
      data-region="topper-mission"
    >
      <div className="hbs-mission-topper__content">
        <h1 className="hbs-global-visually-hidden">Harvard Business School</h1>

        <div className="hbs-mission-topper__title-wrapper">
          <div {...editAttributes?.text} className="hbs-mission-topper__title">
            {/* Title with hoverable details */}
            <span>
              {parts.map(({ details, text }, i) => (
                <span key={i}>
                  {details ? (
                    <button
                      className={cn(
                        "hbs-mission-topper__has-details",
                        i === activePartIndex &&
                          "hbs-mission-topper__has-details--active",
                      )}
                      onClick={() => changeActivePart(i)}
                      aria-label={text}
                      aria-description="advance to this slide in the carousel"
                    >
                      <span>{text}</span>
                    </button>
                  ) : (
                    <span>{text}</span>
                  )}
                  {i !== parts.length - 2 && " "}
                </span>
              ))}
            </span>
          </div>
        </div>

        <div className="hbs-mission-topper__media">
          <div className="hbs-mission-topper__media-controls">
            <button
              className="hbs-mission-topper__media-control--left"
              aria-label="Previous slide"
              onClick={() => {
                if (!activePartIndex && lastSlide) {
                  changeActivePart(lastSlide.partIndex);
                  return;
                }
                if (activePartIndex === firstSlide?.partIndex) {
                  changeActivePart(null);
                  return;
                }
                const prevSlide = slides[currentSlideIndex - 1];
                if (prevSlide) changeActivePart(prevSlide.partIndex);
              }}
            >
              <Icon name="arrow" />
            </button>

            <button
              className="hbs-mission-topper__media-control--right"
              aria-label="Next slide"
              onClick={() => {
                if (!activePartIndex && firstSlide) {
                  changeActivePart(firstSlide.partIndex);
                  return;
                }
                if (activePartIndex === lastSlide?.partIndex) {
                  changeActivePart(null);
                  return;
                }
                const nextSlide = slides[currentSlideIndex + 1];
                if (nextSlide) changeActivePart(nextSlide.partIndex);
              }}
            >
              <Icon name="arrow" />
            </button>
          </div>

          <div
            className="hbs-mission-topper__media-inner"
            ref={mediaEl}
            role="region"
            aria-live="polite"
            key={activePartIndex} // triggers re-render for media animation
          >
            {currentSlide ? (
              <>
                {currentSlide.details?.media && (
                  <MediaAsset {...currentSlide.details.media} />
                )}

                {currentSlide.details?.cta && (
                  <div className="hbs-mission-topper__cta">
                    <div {...currentSlide.details.cta.editAttributes?.text}>
                      {currentSlide.details.cta.text}
                    </div>

                    <CtaLink
                      editAttributes={
                        currentSlide.details.cta.editAttributes?.linkText
                      }
                      className="hbs-mission-topper__cta-link"
                      href={currentSlide.details.cta.linkUrl}
                      isSmall={true}
                    >
                      {currentSlide.details.cta.linkText}
                    </CtaLink>
                  </div>
                )}
              </>
            ) : (
              <>
                <MediaAsset {...defaultMedia} />
                <div className="hbs-global-visually-hidden">
                  Default topper image
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
