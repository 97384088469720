import "./home-page.scss";

import {
  BaseLayout,
  SiteHeaderComponent,
} from "design-system/components/layouts/base";
import {
  MissionTopper,
  MissionTopperProps,
} from "design-system/sites/home/mission-topper/mission-topper";
import { SiteFooterProps } from "design-system/components/universal/site-footer/site-footer";
import { ReactNode } from "react";

export interface HomePageProps {
  topper?: MissionTopperProps;
  footer?: SiteFooterProps;
  header?: SiteHeaderComponent;
  children?: ReactNode;
}

export function HomePage({ topper, children, footer, header }: HomePageProps) {
  return (
    <BaseLayout
      className="hbs-page hbs-home-page"
      footer={footer}
      header={header}
      data-theme="white"
    >
      <main id="skipto-main" tabIndex={-1}>
        {topper && <MissionTopper {...topper} />}

        <div className="hbs-landing-page-body__wrapper">
          <div className="hbs-landing-page-body">
            <div className="hbs-landing-page-body__main">
              <section className="hbs-page-body hbs-landing-page-body__content">
                {children}
              </section>
            </div>
          </div>
        </div>
      </main>
    </BaseLayout>
  );
}
